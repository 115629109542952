<template>
  <div class="max-w-2xl mx-auto">
    <h1 class="text-xl sm:text-2xl md:text-3xl mb-4">Please enter your details to receive a quote for our wellbeing services.</h1>
    <form class="px-8 pt-6 pb-8 mb-4" @submit.prevent="submitForm">
      
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2 text-left" for="contact_name">
          Contact Name<span class="text-red-600"> *</span>
        </label>
        <input class="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="contact_name" type="text" placeholder="Your Name" v-model="user.contact_name" required>
      </div>

      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2 text-left" for="position">
          Position<span class="text-red-600"> *</span>
        </label>
        <input class="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="position" type="text" placeholder="Position" v-model="user.contact_position" required>
      </div>

      <div class="flex-col sm:flex-row flex mb-4 sm:justify-between">
        <div class="sm:w-1/2 sm:pr-4">
          <label class="block text-gray-700 text-sm font-bold mb-2 text-left" for="email">
            Email<span class="text-red-600"> *</span>
          </label>
          <input class="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Email Address" v-model="user.contact_email" required>
        </div>

        <div class="mt-4 sm:w-1/2 sm:pl-4 sm:mt-0">
          <label class="block text-gray-700 text-sm font-bold mb-2 text-left" for="phone">
            Phone<span class="text-red-600"> *</span>
          </label>
          <input class="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="phone" type="phone" placeholder="Contact Number" v-model="user.contact_tel" required>
        </div>        
      </div>

      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2 text-left" for="position">
          Search for your School / Academy below<span class="text-red-600"> *</span>
        </label>
        <div>
          <multiselect v-model="school" :options="schoolList"
              placeholder="Enter School / Academy postcode or name"
              :close-on-select="true" label="label" @input="updateSchool" track-by="label" :block-keys="['Tab', 'Enter']" :show-labels="false" required>
          </multiselect>
        </div>
        <div class="rounded mt-4 bg-green-300 px-4 py-3 text-gray-800 font-bold" v-if="school && school.name">Selected School: {{school.name}}</div>
      </div>

      <div class="flex items-center justify-center mt-12">
        <button class="bg-swb-dark hover:bg-swb hover:shadow-md text-white font-bold py-4 px-12 w-full md:w-auto md:py-2 md:px-12 rounded focus:outline-none focus:shadow-outline">
          Next Step
        </button>
      </div>
    </form>
  </div>
</template>

<script>
const api = "https://admin.kisdigital.co.uk/api/v1/"
const _token = "h72vjlVkHGUN3Se+9yBtdiOpTZFECoi/rxmO2alDuZg=";

import axios from "axios";
import moment from 'moment'
import Multiselect from '../../node_modules/vue-multiselect/src'

// import academys from '../components/academy.json' 

export default {
  name: 'Step1',
  components: {
    Multiselect
  },
  data() {
    return {
      user: {
        step: 2,
        department: "SWB",
        contact_name: "",
        contact_position: "",
        contact_email: "",
        contact_tel: "",
        contact_organisation: "",
        contact_id: "",
        contact_type: "Academy",
        quoteID: "",
        userID: 0,
        enquiryID: "",
        callback: {
          callTime: "am",
          callDate: moment().add(7, 'days').format('D/M/YYYY @ H:m:s'),
        },
        marketing: {
          hearAboutUs: "--",
          currentInsurer: "",
          currentPremium: "",
        },
      },
      school: null,
      schoolList: [],
      jsonData: {},
      schoolItem: [],
    }
  },
  mounted() {
    this.loadProgress();
    this.getMultiselectData();
  },
  methods: {
    updateSchool(data) {
      this.school = data;
      this.user.contact_organisation = data.name;
    },
    getMultiselectData() {
      let self = this;

      if(window.location.host != '192.168.0.31:8080') {
        axios.get(api + "files/academy")
        .then(
          (response) => {
            const schools = response.data.academy;
            self.populateMultiselect(schools);
          }
        );
      }
      else {
        const schools = this.jsonData;
        self.populateMultiselect(schools)
      }
    },
    populateMultiselect(data) {
      let array = [];
      for (var i = 0; i < data.length; i++)
      {
          var item = data[i];
          array.push({
          ref     : item.ref,
          id      : item.id,
          postcode: item.postcode,
          name    : item.name,
          label   : item.label + ' - ' + item.postcode
      });
      }
      this.schoolList = array;
    },
    submitForm() {

      let self = this;
      if(!this.school.name) {
        this.$swal('Form Not Complete', 'Please make sure you select your School / Academy from the dropdown list.', 'error');
      }
      else {
        if(this.user.enquiryID != '') {
          self.nextStep();
        }
        else {
          axios.post(api + "enquiry", { token: _token, enquiry_all: JSON.stringify(self.user) })
          .then(response => {
            self.user.enquiryID = response.data.data;

            self.nextStep();
          })
        }
        
      }
    },
    nextStep() {
      //Build Quote
      this.schoolItem = [{
          id: this.school.id,
          name: this.school.name,
          postcode: "",
          product: "SWB Essential",
          status: "draft",
          acceptance: [],
          discountReason: "",
          loadingReason: "",
          discount: 0,
          loading: 0,
          premium: 0,
          premiumSecondary: 0,
          ref: this.school.ref,
          absences: [],
          absences_existing: [],
          claims: [],
          coverStartDate: "",
          duration: 12,
          additionalCover: "",
          coverEndDate: "",
          absence_no_loading: true,
          autoDiscount: {
              total: 0,
              good_school: 0,
              good_area: 0,
              FTE_ratio: 0,
              MAT: 0
          },
          absenceAverage: 100,
          items: [
              {
                  benefit: 0,
                  breakdown: {
                      base: 0,
                      beforeAutoDiscount: 0,
                      maternity: 0,
                      premium: 0,
                      withAutoDiscount: 0
                  },
                  excess: 0,
                  fte: "0.00",
                  maternity: "0.00",
                  maternityCover: 0,
                  period: 0,
                  premium: 0,
                  staffGrade: "A",
                  staffType: "Teachers",
                  stress: 0
              }
          ],
          loadingNewClaim: 0,
          additionalCoverPrevious: "",
          costStaff: 10,
          activeSAS: false
      }];
      this.user.step = 2;
      this.$emit('stepFinished', [this.user, this.school, this.schoolItem]);
    },
    loadProgress() {
      let retrievedQuote = localStorage.getItem("ls_quote");
      let retrievedUser = localStorage.getItem("ls_user");
      let retrievedSchool = localStorage.getItem("ls_school");
      //decrypt
      if(retrievedQuote) {
        let bytesQuote = this.$CryptoJS.AES.decrypt(retrievedQuote, _token);
        let decryptedQuote = JSON.parse(
          bytesQuote.toString(this.$CryptoJS.enc.Utf8)
        );
        this.schoolItem = decryptedQuote;
      }
      if(retrievedUser) {
        let bytesUser = this.$CryptoJS.AES.decrypt(retrievedUser, _token);
        let decryptedUser = JSON.parse(
          bytesUser.toString(this.$CryptoJS.enc.Utf8)
        );
        this.user = decryptedUser;
      }

      if(retrievedSchool) {
        let bytesSchools = this.$CryptoJS.AES.decrypt(retrievedSchool, _token);
        let decryptedSchools = JSON.parse(
          bytesSchools.toString(this.$CryptoJS.enc.Utf8)
        );
        this.school = decryptedSchools;
      }
    }
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>