<template>
  <div class="bg-gray-100 h-full">
  

    <div>
      <header class="w-full h-20" style="background-color: #D9BCCA">
        <div class="w-full max-w-5xl flex items-center justify-between h-full mx-auto px-4 md:px-2">
          <a href="/" class="text-white flex items-center">
            <img src="@/assets/logo.png" class="w-32 md:w-64"/>
          </a>
          <a href="tel:01773 814407" class="text-swb-dark font-bold hover:text-swb-blue px-4 py-2 rounded-lg bg-white shadow-md">Call Us</a>
        </div>
      </header>
    </div>

    <div class="max-w-5xl bg-white mx-auto w-full">
      <!-- Step Nav -->
      <div class="max-w-xs py-4 md:py-8 mx-auto">
        <ul class="flex justify-center ">
          <li class="w-14 h-14 rounded-full border-4 border-swb-dark text-black flex items-center justify-center font-bold mx-4 text-lg" v-bind:class="{ 'bg-swb-dark': currentStep != 1, 'pointer-events-none': currentStep == 1, 'text-white' : currentStep != 1, 'cursor-pointer': currentStep != 1 }" @click="changeStep(1)">1</li>
          <li class="w-14 h-14 rounded-full border-4 border-swb-dark text-black flex items-center justify-center font-bold mx-4 text-lg" v-bind:class="{ 'opacity-30': currentStep != 2, 'pointer-events-none': !step2Allowed && currentStep != 2, 'cursor-pointer': currentStep == 2 || step2Allowed }" @click="changeStep(2)">2</li>
        </ul>
      </div>

      <hr class="border-gray-300">

      <div class="bg-white py-6 md:py-16">
        <router-view @stepFinished="nextStep"/>
      </div>
    </div>

    <div>
      <footer class="w-full bg-gray-300 py-4">
        <div class="w-full max-w-2xl flex flex-col items-center justify-center h-full mx-auto px-4 md:px-2">
          <p class="text-gray-800 text-center text-xs">Copyright &copy; 2022 School Wellbeing. All Rights Reserved</p>
          <p><a class="text-gray-800 text-center text-xs" href="https://schoolwellbeing.com/wp-content/uploads/2022/08/SWB-Privacy-Notice.pdf" target="_blank">Privacy Notice</a></p>
        </div>
      </footer>
    </div>

  </div>
  
</template>

<script>

const _token = "h72vjlVkHGUN3Se+9yBtdiOpTZFECoi/rxmO2alDuZg=";

let currentdate = new Date();
let datetime =
  currentdate.getDate() +
  "/" +
  (currentdate.getMonth() + 1) +
  "/" +
  currentdate.getFullYear() +
  " @ " +
  currentdate.getHours() +
  ":" +
  currentdate.getMinutes() +
  ":" +
  currentdate.getSeconds();

export default {
  name: 'Main',
  components: {
  },
  data() {
    return {
      currentStep: 1,
      quote: {
        product: "SWB Essential",
        productMeta: "",
        team: "0",
        auth: {
          id: 0,
          name: "",
          request: "",
          status: "",
        },
        status: "enquiry",
        type: "single",
        department: "SWB",
        owner: "Online Form",
        referenceNumber: "0",
        schools: [],
        variations: [],
        variationLive: "0",
        MATpolicyManagement: "",
        MATcoverLevel: "",
        absences: [],
        marketing: [],
        discount: 0,
        discountReason: "",
        loading: 0,
        loadingReason: "",
        matCreated: false,
        meta: {
          startDateTime: datetime,
          endDateTime: "",
          emailText: "",
          quoteText: "",
          notesPrivate: "",
          notesPublic: "",
        },
      },
      step2Allowed: false
    }
  },
  mounted() {
    this.checkStorage();

    if(this.$route.path == '/step-2') {
      this.currentStep = 2;
    }
  },
  methods: {
    nextStep(data) {
      this.saveProgress(data);
      const user = data[0]
      // const school = data[1]
      // const schoolItem = data[2]
      switch(user.step) {
        case 2: 
          this.currentStep = user.step;
          this.$router.push('/step-2')
      }
    },
    saveProgress(data) {
      let userItem = JSON.stringify(data[0]);
      let schoolItem = JSON.stringify(data[1]);
      let quoteItem = JSON.stringify(data[2]);

      let userItemEncrypt = this.$CryptoJS.AES.encrypt(userItem.toString(), _token);
      let schoolItemEncrypt = this.$CryptoJS.AES.encrypt(schoolItem.toString(), _token);
      let quoteItemEncrypt = this.$CryptoJS.AES.encrypt(quoteItem.toString(), _token);

      localStorage.setItem("ls_user", userItemEncrypt);
      localStorage.setItem("ls_school", schoolItemEncrypt);
      localStorage.setItem("ls_quote", quoteItemEncrypt);
    },
    changeStep(step) {
      this.checkStorage();
      this.currentStep = step;
      if(step == 1) {
        this.$router.push('/')
      }
      if(step == 2) {
        this.$router.push('/step-2')
      }
    },
    checkStorage() {
      let retrievedQuote = localStorage.getItem("ls_quote");
      let retrievedUser = localStorage.getItem("ls_user");
      let retrievedSchool = localStorage.getItem("ls_school");

      if(retrievedQuote && retrievedUser && retrievedSchool) {
        this.step2Allowed = true;
      }
    }
  }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
